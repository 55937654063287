.Login{
    height: 100vh;
    display: grid;
    place-items: center;
}
.Login_container{
    height: 90vh;
    width: 90vw;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_right{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Login_left{
    width: 50%;
    height: 100%;
}
.Login_left >img{
    width: 100%;
    height: 100%;
}
.login_right >form{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 70%;
    
}
.login_right > form >input{
    margin: 5px 0;
    padding: 10px 5px;
}
.login_right > form>button{
    margin-top: 15px;
    outline: none;
    border: none;
    padding: 12px 15px;
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    background-color:black;
    transition: 0.3s;
    cursor: pointer;
    font-size: 16px;
}
.login_right >form >button:active{
    scale: 0.95;
}
.line{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.line >p{
    margin: 10px 5px;
    color: grey;
}
.signin>div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.signin > div>img{
    height: 30px;
    margin-right: 10px;
}
.signin{
    padding: 5px 15px;
    width: 60%;
    outline: none;
    border: none;
    transition: 0.3s;
    cursor: pointer;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.signin:active{
    scale: 0.95;
}
.imagelogo{
    height: 100px;
}
.login_right >h3{
    font-family: cursive;
}
.login_right >p{
    margin-top: -10px;
    color: grey;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 1.5px;
}

    .arr{
        font-size: 50px !important;
        margin-right: 5px;
    }
    .dashbo{ 
        opacity: 0;
        visibility: hidden;
        position: absolute;
       transition: 0.4s;
       transform: translateX(-5em);
       
    }
    .goto{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        
    }
    .gobutton{
        width:60%;
        
        
    }
   .gobutton:hover .dashbo{
    opacity: 1;
    visibility: visible;
    position: relative;
    transform: translateX(1em);
    }
    .goto >p{
        margin-top: -5px;
        color: grey;
    }
    .noadm{
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
       height: 100%;
       width: 100%;
       left: 0;
        background-color: rgba(61, 59, 59, 0.4);
    }
    .nocar{
        width: 500px;
        height: 300px;
        background-color: aliceblue;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        animation: pop 0.5s ease-in-out;
    }
    @keyframes pop{
        0%{
            scale: 0;
           
        }
        100%{
            scale: 1;
            
        }
    }
    .closeb{
        font-size: 50px !important;
        transition: 0.3s;
        background-color: tomato;
        color: white;
    }
    .closeb:hover{
        cursor: pointer;
        scale: 1.2;
        transition: 0.3s;
        border-radius: 50%;
        color: white;
    }
 
    .iconb{
       color: red;
        transition: 0.3s;
    }
    .nocar>h2{
        text-transform: uppercase;
        color: dodgerblue;
        margin-bottom: 5px;
        font-weight: 600;
        letter-spacing: 2px;
        font-size: 25px;
    }
    .nocar >p{
        font-size: 18px;
        font-weight: 500;
        margin-top: 15px;
        letter-spacing: 1.5px;
        color: grey;
        
    }