.Dash{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color:aliceblue;
}
.Dashleft{
    display: flex;
    flex-direction: column;
    flex: 0.3;
    height: 90%;
    margin: 0 15px;
    height: 100%;
 
}
.Dashright{
    flex: 0.7;
    display: flex;
    background-color: white;
    height: 90%;
    margin: 0 10px;
    overflow-y: auto;  
    align-items: center;
    justify-content: center;
}
.postcat{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
    height: 100% !important;
    padding-top: 50px;
  
}
.postko{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.post{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 180px;
    width: 270px;
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transition: 0.3s;
}
.post:hover{
cursor: pointer; 
    scale: 1.05;
    
}
.post > img{
    height: 100%;
    width: 100%;
    object-fit:cover;
    
}
.bx-plus{
    position: absolute;
    z-index: 1;
    color: black;
    font-size: 30px;
    background-color:white;
    border-radius: 50%;
    padding: 10px;
    margin-top: 100px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
   
}
.post:hover .bx-plus{
    visibility: visible;
    opacity: 1;
}
.utext{
    margin-top: -0.2px;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.leftlogo{
    padding: 10px 0;
    height:80px;
    background-color: white;
}
.leftlogo>img{
    height: 100%;
    margin-left: 5px;
}
.admin{
    margin: 6px 0;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.admin >img{
    
    border-radius: 50%;
    height: 150px;
}
.count{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.countnews{
    background-color: white;
    width: 100%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px 0;
}
.count1{
    text-align: center;
    margin: 5px;
    cursor: pointer;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;  
}
.count1 >p{
    margin: 0;
  
    padding: 5px 15px;
   
    font-weight: bold;
}
.p{
    color:white;  background-color:#06678D;
}
.mo{
    background-color: green;
    color: white;
}
.l{
    background-color:#ffdd00e9 ;
    color:white;
}
.b{
    background-color: dodgerblue;
    color: white;
}
.s{
    background-color: rgb(110, 62, 4);
    color: white;
}
.a{
    background-color: blueviolet;
    color: white;
}
.e{
    background-color: black;
    color: white;
}
.c{
    background-color: cadetblue;
    color:cornsilk;
}
.count1 >h5{
    margin: 5px;
    padding: 5px 0;
}
.news{
    text-decoration: none;
    color: black;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    background-color: white;
    margin-bottom: 5px;
}

.news >p,.logout >p{
    font-size: 18px;
    letter-spacing: 2px;
}
.bx-news,.bx-power-off{
    font-size: 30px;
    margin-right: 10px;
}
.logout{
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    background-color: white;
    border: none;
    outline: none;
}
.logout:active,.news:active{
    scale: 0.9;
}
.news:hover,.logout:hover,.pdf:hover{
    background-color: rgb(90, 138, 185);
    color: white;
    cursor: pointer;
}
a{
    text-decoration: none;
    color: black;
    text-align: center;
}
.Loading{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    height: 100%;
    width: 100%;
}
.loadcard{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loadcard>img{
    height: 200px;
}
.loadcard >p{
    letter-spacing: 3px ;
    font-weight: 500;
}
/*loader*/
.loader { 
  width:400px; 
  margin:0 auto;
  border-radius:10px;
  border:4px solid transparent;
  position:relative;
  padding:1.5px;
}
.loader:before {
  content:'';
  border-radius:10px;
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.loader .loaderBar { 
  position:absolute;
  border-radius:10px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:dodgerblue; 
  width:0;
  animation:borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}
.bxs-file-pdf{
    margin-right: 5px;
    color: brown;
    font-size: 35px;
    transition: 0.5s all ease-in-out;
}
.news:hover .bxs-file-pdf{
   transition: 0.5s;
    color: white;
}