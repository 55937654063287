.viewpdf{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
 
}
.viewpdf1{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
}
.pdfcard{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: 0.3s all ;
  width: 300px;
    
}
.pdfico{
    font-size: 180px;
}
.pdfcard >p{
    margin-top: -5px;
}
.pdfcoun{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pdfcoun >p{
    margin-right: 70px;
    letter-spacing: 1.5px;
    background-color: black;
    color: white;
    padding: 5px 15px;
}
.Viewbu{
    margin-right: 10px !important;
}
.pdfview{
 display: flex;
 align-items: center;
 justify-content: center;
 position: absolute;
 z-index: 5;
 flex-direction: column;
 width: 100%;
 bottom: 0;
 height: 100%;
 background-color: whitesmoke;
}
iframe{
    width: 98%;
    height: 90%;
}
.pdfview >div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}
.getback1{
    padding: 10px 15px;
    background-color: black;
    color: white;
    border-radius: 5px;
    transition: 0.3s all;
}
.getback1:hover{
    background-color: rgb(69, 68, 68);
    scale: 1.02;
}