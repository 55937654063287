.ps{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ps>form{
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 380px;
    border-radius: 10px;
    background-color: white;
   
}
.psy1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    border: 1px dashed black;
    width: 80%;
    border-radius: 10px;
    padding: 20px 0;
    cursor: pointer;
  
}
.psy1 >h5{
    letter-spacing: 1px;
    margin: 0;
    color: rgb(188, 186, 186);
}
.upfi{
    font-size: 100px !important;
    color: dodgerblue;
   transition: 0.3s all ease-in-out;
}
.upfi:hover{
    position: relative;
    scale: 1.1;
    transition: 0.3s all ease ;
}
.uplo{
    margin-top:10px !important ;
}
.finote{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 80%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    background-color: aliceblue;
    padding: 4px 10px;
}
.pdfshow{
    margin-left: 10px;
    
}
.delf{
    font-size: 18px !important;
}
.finote>div{
    display: flex;
    align-items: center;
    justify-content: center;
}
/* Pdfsec css*/
.Pdfsec{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.Pdfsec > div{
    width: 50%;
    height: 450px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Pdfsec > div>img{
    height: 100%;
    width: 100%;
  object-fit: cover;
}
.uploadsli{
    display: block;
    background-color: black;
    color: white;
    padding: 10px 25px;
    transition: 0.3s all ease-in-out;
    width: 80%;
    letter-spacing: 10px;
    text-transform: uppercase;
    font-size: 18px;
    border-radius: 8px;
    font-weight: 600;
}
.uploadsli:active{
    scale: 0.95;
}
.uploadsli:hover{
    transition: 0.3s all ease-in-out;
    background-color: white;
    color: darkorchid;
    border: 1px solid rgb(228, 227, 227);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.waitm{
    color:rgba(236, 33, 155, 0.853);
    letter-spacing: 2px;
    animation: up 0.5s;
}
@keyframes up {
    0%{
        transform: scale(0);
       
    }
    100%{
        transform: scale(1);
    }
}
.getback{
    position: absolute;
    z-index: 2;
    top: 0;
    right:0;
    padding: 10px 15px;
    margin-right: 20px;
    margin-top: 15px;
    background-color: black;
    color: white;
    border-radius: 5px;
    transition: 0.3s all;
}
.getback:hover{
    background-color: rgb(69, 68, 68);
    scale: 1.02;
}